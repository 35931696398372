html, body, #root, .App, .fadeinanime, .flex-container, .fullH{
    min-height: 100vh;
}
body{
    font-family: 'Ubuntu', sans-serif;
    font-family: 'Acme', sans-serif;
    font-family: 'Righteous', cursive;
    min-height: 100vh;
    
    background: rgb(152,1,1);
    background: linear-gradient(295deg, rgba(152,1,1,1) 0%, rgba(227,1,0,1) 100%);

    background-image: url('/backdrop.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
}
.greeny{
    width: 105.99px;
    height: 125.51px;
    position: absolute;
    left: -15px;
    top: -30px;
    z-index: 1000000;
}

.greeny img {
    width: 100%;
    height: 100%;
}
.error-msg{
    color: #ff9898; 
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 12px;
    margin-left: 20px;
    margin-right: 20px;
}
.success-msg{
    color: #e8f6f0; 
    background-color: #49cc90;;
    padding: 10px;
    border-radius: 10px;
    font-size: 18px;
}
.max400{
    max-width: 400px;
}
.darkred .aws-btn__content{
    background-color: darkred !important;
    border-color: darkred !important;
}
.App{
    margin-left: auto;
    margin-right: auto;
}

.footer{
    margin-top: 30px;
    text-align: center;
    color: #fff;
    text-align: center;
    bottom: 5px;
    /* position: absolute;
    width:300px;
    left: 50%;
    right:25%;
    margin-left:-150px; */
}
.startPlaying{
    margin-top: 20px;
    width: 250px;
}

div.startPlaying.option{
    border-radius: 10px;
    border: 1px solid #f7f7f7;
    color: white;
    cursor: pointer;
    padding: 20px 0px;
    background-color: #555555;
}

.margin-auto {
    margin: auto;
}

.w400{
    width: 400px;
}

.center-div {
     margin: 0 auto;
     text-align: center;
}
input[type=tel], select{
    margin-top: 20px;
    width: 70%;
}
.text-center{
    text-align: center;
}
.aws-btn--disabled span{
    text-decoration: none !important;
}

.h70{
    height: 70px !important;
}
.mt-5{
    margin-top: -5px !important;
}
.mt-10{
    margin-top: -10px !important;
}
.mt20{
    margin-top: 20px !important;
}
.mt25{
    margin-top: 25px !important;
}
.mt50{
    margin-top: 50px !important;
}
.mt100{
    margin-top: 100px !important;
}
.mt-50{
    margin-top: -50px !important;
}
.mt-40{
    margin-top: -40px !important;
}
.mt-30{
    margin-top: -30px;
}
.mt5{
    margin-top: 5px;
}
.mt10{
    margin-top: 10px;
}
.mr6{
    margin-right: 6px;
}
.mr5{
    margin-right: 5px;
}
.ml5{
    margin-left: 5px;
}
.l-s-1{
    letter-spacing: 1px;
}

button.aws-btn--disabled{
    cursor: auto;
    min-width: 45px !important;
}
.text10{
    font-size: 20px;
    font-weight: 500;
}
.t_and_c{
    font-size: 12px;
    font-family: 'Ubuntu';
    color: white !important;
    text-decoration: none;
}
.full-width{
    width: 100%;
}
.white{
    color: #fff;
}

.red{
    color: #c80000;
}

.grey{
    color: #c6c6c6;
}

.m-20{
    margin: 20px;
}

.mt40{
    margin-top: 40px;
}

.text30{
    font-size: 30px;
    font-weight: 800;
}
.text20{
    font-size: 30px;
    font-weight: 500;
}
.text22{
    font-size: 22px !important;
}
.text16{
    font-size: 18px !important;
    font-weight: normal;
}

.text14{
    font-size: 14px !important;
}

.text14{
    font-size: 14px;
}
.iconImg{
    width: 25px;
    height: 25px;
    vertical-align: middle;
    margin-right: 3px;
}
.text15{
    font-size: 15px;
}

.logo{
    vertical-align:middle;
    height: 100px;
    margin-bottom: 20px;
}

.logo-sm{
    height: 35px;
    vertical-align:middle;
    margin-bottom: 20px;
}

.animrotate5 {
    -webkit-animation:rotate5 1s linear infinite;
    -moz-animation:rotate5 1s linear infinite;
    animation:rotate5 1s linear infinite;
}

.animrotate-5 {
    -webkit-animation:rotate-5 1s linear infinite;
    -moz-animation:rotate-5 1s linear infinite;
    animation:rotate-5 1s linear infinite;
}

@-moz-keyframes rotate5 { 
    50% { -moz-transform: rotate(10deg); } 
    100% { -moz-transform: rotate(0deg); } 
}
@-webkit-keyframes rotate5 { 
    50% { -webkit-transform: rotate(10deg); }
    100% { -webkit-transform: rotate(0deg); } 
}
@keyframes rotate5 { 
    50% { -webkit-transform: rotate(10deg); transform:rotate(10deg); } 
    100% { -webkit-transform: rotate(0deg); transform:rotate(0deg); } 
}


@-moz-keyframes rotate-5 { 
    50% { -moz-transform: rotate(-5deg); } 
    100% { -moz-transform: rotate(0deg); } 
}
@-webkit-keyframes rotate-5 { 
    50% { -webkit-transform: rotate(-5deg); }
    100% { -webkit-transform: rotate(0deg); } 
}
@keyframes rotate-5 { 
    50% { -webkit-transform: rotate(-5deg); transform:rotate(-5deg); } 
    100% { -webkit-transform: rotate(0deg); transform:rotate(0deg); } 
}

.rotate-5 {
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    transform: rotate(-5deg);
}
.rotate5 {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
}

.circleBase {
    border-radius: 50%;
}

.type1 {
    width: 50px;
    height: 50px;
    background: #fff;
    padding: 8px;
}
.type3 {
    width: 30px;
    height: 30px;
    background: #fff;
    padding: 8px;
}
.hide{
    opacity: 0;
}
.type2 {
    width: 100px;
    height: 100px;
    background: #fff;
    padding: 8px;
}
.h20{
height: 10px;
overflow: hidden !important;
}
.blink1{
    -webkit-animation:eyeblink 0.2s ease 4s;
    -moz-animation:eyeblink 0.2s ease 4s;
    animation:eyeblink 0.2s ease 4s;
}
.blink2{
    -webkit-animation:eyeblink 0.25s ease 4s 2;
    -moz-animation:eyeblink 0.25s ease 4s 2;
    animation:eyeblink 0.25s ease 4s 2;
}
.angrybrowright{
    transform: rotate(45deg);
    transform-origin: 20% 40%;
    border: 2px solid #cb0100;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    float: left;
    width: 30px;
    margin-left: 25px;
}

.aws-btn--disabled .aws-btn__wrapper:before, .aws-btn--secondary .aws-btn__wrapper:before{
    background-color: white !important;
}

.angrybrowleft{
    float: right !important;
    transform: rotate(-45deg);
    transform-origin: 20% 40%;
    border: 2px solid #cb0100;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    float: left;
    width: 30px;
    margin-right: 8px
}
.eye{
    background-color:#cb0100;
    height: 15px;
    width: 20px;
    border-radius: 100%;
}
.eye2{
    background-color:#cb0100;
    height: 20px;
    width: 20px;
    border-radius: 100%;
}
.mouth2{
    border: 5px solid #cb0100;
    display: inline-block;
    min-width: 15px;
    min-height: 4em;
    padding: 0.3em;
    border-left-color: transparent;
    border-right-color: transparent;
    border-left: 0px;
    border-right: 0px;
}
.mouth{
    border: 2px solid #cb0100;
    display: inline-block;
    min-width: 35px;
    min-height: 4em;
    padding: 0.3em;
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom: 0px;
}
.arrow-up {
    width: 0; 
    height: 0; 
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    
    border-bottom: 5px solid #cb0100;
  }

@-moz-keyframes eyeblink { 
    0%   { height:   20px; }
  100% { height: 5px; }

  0%   { margin-top:   0px; }
  100% { margin-top: 3px; }
}
@-webkit-keyframes eyeblink { 
    0%   { height:   20px; }
  100% { height: 5px; }

  0%   { margin-top:   0px; }
  100% { margin-top: 3px; }
}
@keyframes eyeblink { 
    0%   { height:   20px; }
  100% { height: 5px; }

  0%   { margin-top:   0px; }
  100% { margin-top: 3px; }
}

.teardrop1{
    -webkit-animation:teardrop 1s ease 2s;
    -moz-animation:teardrop 1s ease 2s;
    animation:teardrop 1s ease 2s;
}
.teardrop2{
    -webkit-animation:teardrop 1s ease 1s;
    -moz-animation:teardrop 1s ease 1s;
    animation:teardrop 1s ease 1s;
}
.teardrop3{
    -webkit-animation:teardrop 1s ease 3s;
    -moz-animation:teardrop 1s ease 3s;
    animation:teardrop 1s ease 3s;
}

@-moz-keyframes teardrop { 
    0%{opacity: 1;}
    50%{opacity: 0.8;}
    75%{opacity: 0.5;}
    100%{opacity: 0;}

  0%   { margin-top:   0px; }
  100% { margin-top: 70px; }
}
@-webkit-keyframes teardrop { 
    0%{opacity: 1;}
    50%{opacity: 0.8;}
    75%{opacity: 0.5;}
    100%{opacity: 0;}

  0%   { margin-top:   0px; }
  100% { margin-top: 70px; }
}
@keyframes teardrop { 
    0%{opacity: 1;}
    50%{opacity: 0.8;}
    75%{opacity: 0.5;}
    100%{opacity: 0;}
  
  0%   { margin-top:   0px; }
  100% { margin-top: 70px; }
}

.type1 span{
    font-size: 30px;
    line-height: 50px;
    color: #cb0100;
}

.cur-hand{
    cursor: pointer;
}

.text200{
    font-size: 200px;
}
div.inline{
    display: inline-block;
}

div.table{
    display: table;
}

.ml10{
    margin-left: 10px !important;
}
.ml-50{
    margin-left: -50px !important;
}
.ml20{
    margin-left: 20px !important;
}
.ml30{
    margin-left: 30px !important;
}
.ml35{
    margin-left: 35px !important;
}
.processingDiv{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 860px;
    z-index: 999999;
    overflow: hidden;
    text-align: center;
    display: table;
    background: rgb(152,1,1);
    background: linear-gradient(295deg, rgba(152,1,1,1) 0%, rgba(227,1,0,1) 100%);
}
.processingDiv #content{
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}
.processingDiv #content span{
    color: #fff;
    font-size: 40px;
}

.text-left{
    text-align: left;
}
.mt0{
    margin-top: 0px !important;
}
.mb0{
    margin-bottom: 0px !important;
}

.app-name-5{
    font-size: 180px;
    font-weight: 400;
    font-style: italic;
    color: #fff;
    margin-top: -70px;
    margin-left: -65px;
    position: absolute;
    z-index: -1;
}

.float-left{
    float: left;
}

.float-right{
    float: right;
}

.select-network{
    padding: 20px;
    background-color: rgba(0,0,0,0.6);
    margin-right: 10px;
}

.clear-fix{
    overflow: auto;
}

.md-overlay{
    background: #cb0100 !important;
}

.md-content{
    background: #cb0100;
}
input[type=tel]{
    width: 100% !important;
}
.select-network.selected{
    background-color: #cb0100;
}
.inline-btn{
    display: initial !important;
}
.pr25{
    padding-right: 25px;
}
.pl25{
    padding-left: 27px;
}
.mt25{
    margin-top: 25px !important;
}
.textInverse{
    font-size: 22px;
    color: #21635e;
}
.smallImg{
    width: 175px;
    margin-bottom: 10px;
}

.smallImg2{
    width: 235px;
    margin-bottom: 10px;
}

.fadeinanime{
    -webkit-animation: fadeIn 0.5s ease-out;
    -moz-animation: fadeIn 0.5s ease-out;
    -o-animation: fadeIn 0.5s ease-out;
    animation: fadeIn 0.5s ease-out;
}
@-webkit-keyframes fadeIn {
    0% {display: none;opacity: 0;}
    1% {display: block;opacity: 0;}
    50% {display: block;opacity: 0.5;}
    100% {display: block;opacity: 1;}
}
@-moz-keyframes fadeIn {
    0% {display: none;opacity: 0;}
    1% {display: block;opacity: 0;}
    50% {display: block;opacity: 0.5;}
    100% {display: block;opacity: 1;}
}
@-o-keyframes fadeIn {
    0% {display: none;opacity: 0;}
    1% {display: block;opacity: 0;}
    50% {display: block;opacity: 0.5;}
    100% {display: block; opacity: 1;}
}
@keyframes fadeIn {
    0% {display: none; opacity: 0;}
    1% {display: block; opacity: 0;}
    50% {display: block;opacity: 0.5;}
    100% {display: block; opacity: 1;}
}

.fadeoutanime{
    -webkit-animation: fadeOut 0.5s ease-out;
    -moz-animation: fadeOut 0.5s ease-out;
    -o-animation: fadeOut 0.5s ease-out;
    animation: fadeOut 0.5s ease-out;
}
@-webkit-keyframes fadeOut {
    0% {display: block;opacity: 1;}
    1% {display: block;opacity: 1;}
    100% {display: block;opacity: 0;}
}
@-moz-keyframes fadeOut {
    0% {display: block;opacity: 1;}
    1% {display: block;opacity: 1;}
    100% {display: block;opacity: 0;}
}
@-o-keyframes fadeOut {
    0% {display: block;opacity: 1;}
    1% {display: block;opacity: 1;}
    100% {display: block;opacity: 0;}
}
@keyframes fadeOut {
    0% {display: block;opacity: 1;}
    1% {display: block;opacity: 1;}
    100% {display: block;opacity: 0;}
}

.banner {
    max-width: 100%;
}

#img-question{
    max-height: 250px;
    max-width: 100%;
}

.hide{
    display: none;
}

.fade{
    opacity: 0;
}

.middle-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 50px;
  }
  .lds-spinner div {
    transform-origin: 32px 32px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

.limitNoticeContainer .item{
    padding: 12px;
    border-radius: 20px;
    background-color: #555555;
    color: #fff;
}

.aws-btn--secondary .aws-btn__wrapper:hover .aws-btn__content{
    background-color: #555555 !important;
}

.aws-btn__wrapper .aws-btn__content{
    background-color: #555555;
    border-color: #555555;
    color: #fff;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.aws-btn.red .aws-btn__content span{
    color: #8f3030;
}

.aws-btn.green .aws-btn__content span{
    color: #018001;   
}

.aws-btn.purple .aws-btn__content span{
    color: #862a86;   
}

.aws-btn.orange .aws-btn__content span{
    color: #ff5437;   
}

.white-bg{
    background-color: #fff;
}

.grecaptcha-badge {
    display: none;
}

.friend-number-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 140px;
}

.friend-number-container input {
    padding: 10px;
    width: 300px;
    text-align: center;
    font-size: 14px;
}

.absolute {
    position: absolute;
}

.behind {
    z-index: -1;
}

.Eixt a,
.Exit a:visited,
.Exit a:active {
  color: #fff
}
.Exit a {
  text-decoration: none;
}